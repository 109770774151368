import React from 'react'
import {Link} from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ServiceSidebarCustomSpreadsheets = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <AnchorLink to="#administrative-team-projects">
                        Administrative Team Projects
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to="#teacher-projects">
                        Teacher Projects
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to="#support-personnel-projects">
                        Support Personnel Projects
                    </AnchorLink>
                </li>
                <li>
                    <Link to="/contact">
                        Custom Project 
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default ServiceSidebarCustomSpreadsheets