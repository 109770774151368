import React from 'react'
import ServiceSidebarCustomSpreadsheets from './ServiceSidebarCustomSpreadsheets'
import education3 from '../../assets/images/education/Education3.png'
import { Link } from 'gatsby'

const ServiceDetailsContentCustomSpreadsheets = () => {

    const ShowExampleLink = ({url, name}) => {
        return (
            <a className="default-btn mx-2" href={url} target="_blank" rel="noreferrer">
                <i className="flaticon-right"></i>
                    {name}
                <span></span>
            </a>
        )
    }

    const ShowVideoLink = ({url, name}) => {
        return (
            <a className="default-btn" href={url} target="_blank" rel="noreferrer">
                <i className="flaticon-right"></i>
                    {name}
                <span></span>
            </a>
        )
    }

    return (
        <section className="services-details-area pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-desc services-details-desc-top">
                            <h3>About this service</h3>
                            <p>Smarter Spreadsheets works with a variety of school personnel from principals to teachers to administrative assistants to find ways to save time and energy. By working together, we can create projects that meet your diverse needs. Look at some examples from other clients or reach out to design something specific for your school.</p>
                            <p>Pricing for spreadsheet creation is $80/hr. Time per project varies based on scope and condition of data sets. Estimates for common projects are given. Estimates for custom projects are provided after a free consultation meeting.</p>
                        </div>
                        <br />

                        <div className="services-details-image">
                            <img src={education3} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <h3 className="service-header" id="administrative-team-projects">Administrative Team Projects</h3>
                            <p>Smarter Spreadsheets has helped admin teams with time-saving and user-friendly solutions to data management.  Look through these examples to see if one meets your needs or <Link to="/contact" className="contact">contact us</Link> for a custom spreadsheet.</p>
                            <div className="subservice-package">
                                <h4>Student contact collection made easy</h4>
                                <p>Student Information Systems are not always user-friendly and require multiple steps to gather a variety of pieces of information on separate screens. Spreadsheet functions can pull student data about schedules and contact information to reach out to teachers and parents easier.  By typing in a student name, you can easily copy teacher/parent emails, Zoom links or anything else to expedite communications.</p>
                                <ul>
                                    <li><span>Customizable</span></li>
                                    <li><span>Saves hours of looking up contact information over a school year</span></li>
                                    <li><span>Estimated project time: 4-5 hours</span></li>
                                </ul>
                                <div class="d-flex justify-content-center align-items-center">
                                    <ShowExampleLink url="https://docs.google.com/spreadsheets/d/1NSOY2ugnkXTJzDDuni8dIyLFsH170lDWuivztXoPmUM/edit?usp=sharing" name="View Example" />
                                    <ShowVideoLink url="https://youtu.be/3wa7EJKYUJg" name="See it in Action" />
                                </div>
                            </div>
                            <div className="subservice-package">
                                <h4>Attendance/out of classroom checker</h4>
                                <p>Setting up a form-based sign-out system can help you identify trends of students avoiding class or identify students for bathroom graffiti or vandalism. Attendance trends can also be identified using scheduled data downloads from your SIS to keep an eye on student absences/tardies.</p>
                                <ul>
                                    <li><span>Useful for problem-solving meetings</span></li>
                                    <li><span>Creates logs for behavior issues that arise during out of classroom time</span></li>
                                    <li><span>Estimated project time: 3-4 hours</span></li>
                                </ul>
                                <div class="d-flex justify-content-center align-items-center">
                                    <ShowExampleLink url="https://docs.google.com/spreadsheets/d/1m7OzyYAcW6TydBBe0rJtfSAwr3_6tLpsqnFI_GtNbvA/edit?usp=sharing" name="View Example" />
                                    <ShowVideoLink url="https://youtu.be/kYeWeEYStQI" name="See it in Action" />
                                </div>
                            </div>

                            <h3 className="service-header" id="teacher-projects">Teacher Projects</h3>
                            <p>Collecting data from students is a great first step.  But how can teachers use data to better help their students and incorporate student choice?  Spreadsheets can help.  Look to see if these services might save you time or help your students.  If not, <Link to="/contact" className="contact">contact us</Link> for a custom solution.</p>
                            <div className="subservice-package">
                                <h4>Grouping Data</h4>
                                <p>Looking for a way to group students based on student choice or assessment data?  Spreadsheet functions can save you time and make it easier to share those selections with others.</p>
                                <ul>
                                    <li><span>Increase student engagement by matching choice to activity</span></li>
                                    <li><span>Easy-to-share document merges available</span></li>
                                    <li><span>Estimated project time: 1-4 hours depending on the scope of the project</span></li>
                                </ul>
                                <div class="d-flex justify-content-center align-items-center">
                                    <ShowExampleLink url="https://docs.google.com/spreadsheets/d/1UrqLxkvYgiVhNcKXvyjBzoJxFOFg284ujdMnyDfIdHo/edit?usp=sharing" name="View Example" />
                                    <ShowVideoLink url="https://youtu.be/9BywZWWuyX8" name="See it in Action" />
                                </div>
                            </div>
                            <div className="subservice-package">
                                <h4>Assessment Data</h4>
                                <p>Assessment data from other services or your own collection can be sorted, ranked, and organized in a variety of ways to better match instruction to student needs.  You can also evaluate the effectiveness of interventions and track their implementation.</p>
                                <ul>
                                    <li><span>Enable teachers to meet students at their level</span></li>
                                    <li><span>Useful for program review or interventions</span></li>
                                    <li><span>Estimated project time: 1-5 hours depending on the scope of the project</span></li>
                                </ul>
                                <div class="d-flex justify-content-center align-items-center">
                                    <ShowExampleLink url="https://docs.google.com/spreadsheets/d/1ITxT5vKSS397D44V5bQ4-MXyekrOYel6_xiNhbFy7S4/edit?usp=sharing" name="View Example" />
                                    <ShowVideoLink url="https://youtu.be/pgza8VaEJUQ" name="See it in Action" />
                                </div>
                            </div>

                            <h3 className="service-header" id="support-personnel-projects">Support Personnel Projects</h3>
                            <p>Student data is collected by lots of different staff members.  Many of those staff members have not had specific training in organizing data to make their jobs easier to improve communication.  Smarter Spreadsheets can help your staff, regardless of their role.  Look at some of these options or <Link to="/contact" className="contact">contact us</Link> to discuss how we might help.</p>
                            <div className="subservice-package">
                                <h4>Student Information Systems</h4>
                                <p>Student Information Systems might collect data used for state mandated reporting, but it is not always organized in a way that makes it easy to collate and share.  Spreadsheet functions can quickly group and count students based on flexible criteria for reporting purposes.</p>
                                <ul>
                                    <li><span>Quick data collection for health, fitness or assessment data for state reporting.</span></li>
                                    <li><span>Easy to use and customizable</span></li>
                                    <li><span>Estimated project time: 3-5 hours</span></li>
                                </ul>
                                <div class="d-flex justify-content-center align-items-center">
                                    <ShowExampleLink url="https://docs.google.com/spreadsheets/d/1A_kPeKGcYHefkuYLk7yUHJf3mej3r6BNv0rIWjyoY50/edit?usp=sharing" name="View Example" />
                                    <ShowVideoLink url="https://youtu.be/OVEvwdQfUr4" name="See it in Action" />
                                </div>
                            </div>
                            <div className="subservice-package">
                                <h4>Mail/document merges for personalized reports</h4>
                                <p>Collecting data is useful, but sharing that data is often just as important.  Using Google Sheet Extensions, information that has been organized can be shared in individualized reports via email or through PDF creation.</p>
                                <ul>
                                    <li><span>Great tool for parent communication</span></li>
                                    <li><span>Useful to share teacher materials for testing or special schedules</span></li>
                                    <li><span>Estimated project time: 2-4 hours depending on scope of the project</span></li>
                                </ul>
                                <div class="d-flex justify-content-center align-items-center">
                                    <ShowExampleLink url="https://docs.google.com/spreadsheets/d/1JzyhL8gPp4--HaCDUpKtQOXVY-OMsccrcph8tatV_u8/edit?usp=sharing" name="View Example" />
                                    <ShowVideoLink url="https://youtu.be/jpzsag4Yg9w" name="See it in Action" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarCustomSpreadsheets />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentCustomSpreadsheets